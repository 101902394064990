import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Tabs, Tab, Nav } from "react-bootstrap";
import Slider from "react-slick";
import ScrollAnimation from 'react-animate-on-scroll';
import dateFormat from 'dateformat';
import NoImg from '../../../images/no-image.png';

import PlayVideo from '../../../components/Play/PlayVideo';
import {youtube_parser, CustomLinks} from "../../common/utils";
import {ImageModule} from "../../../modules/Image_Module";
import {FeatureInsights} from "../../../queries/common_use_query";
import "./News.scss";
import HTMLReactParser from 'html-react-parser';

// Header component

const News = (props) => {
    const [isPlay, setPlay] = useState(false);
    const [videoId, setVideoId] = useState('');
    const [newsList, setNewsList] = useState([]);

    const { loading, error, data } = FeatureInsights(props.category);

    useEffect(()=>{
        data && data.newsAndInsights && setNewsList(data.newsAndInsights);

    },[data])


    const settings = {
        dots: false,
        speed: 3000,
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        pauseOnHover:false,
        autoplay:true,
        autoplaySpeed:8000,
        dots: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 768,
                settings: {
                    dots: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    //console.log("newsAndInsights", data && data.newsAndInsights, tabKey);

    var setting_val = {...settings, infinite:newsList && newsList.length > 3 ? true : false};
    return (
        <React.Fragment>
            {newsList.length > 0 && 
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

                <section className={`news-section white`}>
                    <Container>
                        <Row>
                            <Col className="news-content">
                                <div className="news-heading">
                                    <h2>{props.CustomTitle ? props.CustomTitle : "You may also like..."}</h2>
                                </div>

                                <Tab.Container defaultActiveKey={"0"}>
 
                                    <Tab.Content>
                                        <Tab.Pane eventKey={"0"}>
                                                {  
                                                    <Slider {...setting_val}>
                                                    {
                                                        
                                                        newsList.map((item, index) => {
                                                            if(item.id!=props.news_id) {
                                                            var youtube_id = item.Embed_Video_URL && item.Embed_Video_URL && youtube_parser(item.Embed_Video_URL);

                                                            return(
                                                                <div className="news-wrapper">
                                                                    
                                                                    <div className="news-img img-zoom">
                                                                        <Link to={`${CustomLinks.insights}/${item.URL}`}>
                                                                        <picture>
                                                                            {item.Image ?  
                                                                                <ImageModule ImageSrc={item.Image} ggfx_results={item.ggfx_results} 
                                                                                altText={item.Name + " - Strettons"} imagename="news-and-insights.Image.small_image" strapi_id={item.id} />
                                                                                : <img src={NoImg} alt={item.Name} /> 
                                                                            }
                                                                        </picture>
                                                                        </Link>
                                                                        {
                                                                            item.Embed_Video_URL && 
                                                                            <div className="video-buttons" onClick={(e) => { setVideoId(youtube_id);  setPlay(true) }}>
                                                                                <strong className="video-btn">
                                                                                    <i className="icon-video-black"></i>
                                                                                </strong>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    
                                                                    <div className="news-details">
                                                                        {/* <h5>
                                                                            {dateFormat(item.Published_Date, "mmmm dd yyyy")} / {item.news_and_insights_category.Name}
                                                                        </h5> */}
                                                                        <h5>
                                                                            {item.news_and_insights_category.Name}
                                                                        </h5>
                                                                        <h2>
                                                                            <Link to={`${CustomLinks.insights}/${item.URL}`}>
                                                                                {item.Title}
                                                                            </Link>
                                                                        </h2>
                                                                    </div>
                                                                </div>
                                                            )
                                                            }
                                                        })
                                                    }                                       
                                                    </Slider>
                                                }
                                        </Tab.Pane>
                                    </Tab.Content>
                                        
                                </Tab.Container>
                            </Col>
                        </Row>
                    </Container>
                </section>

            </ScrollAnimation>
            }
            
            
            <PlayVideo
                isOpen={isPlay}
                isCloseFunction={setPlay}
                videoId={videoId}
                isAutoPlay={1}
            />
        </React.Fragment>
    )
}
export default News
