import React, {useState, useEffect, useRef, createRef} from "react"
import { Helmet } from "react-helmet";
import { Container, Row, Card, Col } from "react-bootstrap";
import axios from "axios"

import Layout from "../components/layout";
import SEO from "../components/seo";

import InsightsLinks from "../components/InsightsDetails/InsightsLinks";
import Management from "../components/InsightsDetails/PortfolioManagement/Management";
import YouMayAlsoLike  from '../components/Home/News/SimilarNews'
import {Insight_Details} from "../queries/common_use_query"
import {capitalize} from '../components/common/utils';

import BrandLogo from "../images/logo002.svg";

function OfficeDetailsTemplate(props) {

  //console.log("search", search);
  const [news_details, SetDetails] = useState("");

  const {loading, error, data} = Insight_Details(props.pageContext?.slug);

  useEffect(()=>{
    data && data.newsAndInsights.length > 0 && SetDetails(data.newsAndInsights[0]);
  },[data])

  var myMetaTitle = "";
  var myMetaDesc = "";
  var myMetaImg = "";

  if( props?.pageContext?.title ) {
      myMetaTitle = capitalize(props.pageContext.title);
      myMetaDesc = "Read about "+myMetaTitle+" here and subscribe to our newsletter to stay up-to-date about everything going on at Strettons.";
  }

  let processedImages = JSON.stringify({});
  const STAGE_ENV = process.env.GATSBY_STRAPI_GGFX_ENV || "i.dev";
  
  if (news_details?.imagetransforms?.Image_Transforms) {
    processedImages = news_details?.imagetransforms?.Image_Transforms;
    var resized_images = JSON.parse(processedImages);
    const SrcCftle = news_details?.Image && news_details?.Image?.url.substring(news_details?.Image && news_details?.Image?.url.indexOf(STAGE_ENV));
    const ProcessedUrl = resized_images[SrcCftle] && resized_images[SrcCftle]?.jpg ? resized_images[SrcCftle]?.jpg['472x300'] : 
                         resized_images[SrcCftle]?.png ? resized_images[SrcCftle]?.png['472x300'] : news_details?.Image && news_details?.Image?.url;
    myMetaImg = ProcessedUrl;
  }

  return (
        loading ? (
          <section className={"loader-wrapper"}>
              <div id="loader-wrapper">
                  <div id="loader" className="new-loader">
                      <img className="logo-white" src={BrandLogo}  alt="logo"/>
                  </div>
              </div>
          </section>
      ) :

      <Layout GQLPage="" Layout="Without_Banner" classNames={"search-header person-header news_details_wrapper details-header"} popular_search_slug="insights">
        
        <SEO title={myMetaTitle} description={myMetaDesc} image={myMetaImg}/>
        
        <Helmet bodyAttributes={{ class: `body_cls` }} />

        <InsightsLinks page_title={news_details && news_details.Title} />

        {news_details && <Management news_details={news_details} /> }
        
        <YouMayAlsoLike CustomTitle={"You may also like..."} news_id={news_details?.id} category={news_details && news_details?.news_and_insights_category?.Name} />

      </Layout>
  )
}

export default OfficeDetailsTemplate